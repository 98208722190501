import React, { useEffect } from "react";
// import LogIn from "./AthenticationComponent/LogIn";
import axios from "axios";
const JuzzItCRMintegration = () => {
  useEffect(() => {
    const postData = async () => {
      const url =
        "https://api.neodove.com/integration/fb?integration_id=23288ac3-4606-4346-9048-1b71a6501599";
      console.log("Request sent");
      const data = {
        object: "page",
        entry: [
          {
            id: "101734858445127",
            time: 1610558601,
            changes: [
              {
                value: {
                  form_id: "428224518605742",
                  leadgen_id: "1000750933750315",
                  created_time: 1610558600,
                  page_id: "101734858445127",
                },
                field: "leadgen",
              },
            ],
          },
        ],
      };

      try {
        const response = await axios.post(url, data, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        console.log("Response from API:", response.data);
      } catch (error) {
        console.error("Error posting data:", error);
      }
    };

    postData();
  }, []);
  return <></>;
};

export default JuzzItCRMintegration;
